import { Observable, of, throwError } from 'rxjs';

import { delay, mergeMap, retryWhen, tap } from 'rxjs/operators';

/**
 * rxjs operator to retry on 504 (Gateway Timeout)
 *
 * This operates on a HttpResponse and retries the request if the status is 504.
 * It should retry the request by default 3 times, but this can be overridden by
 * passing a number to the operator.
 * When the request has been retried the number of times specified and still return
 * a 504 status, it will throw
 */
export const retryOn504 =
  <T>(maxRetries = 3) =>
  (source$: Observable<T>) => {
    return source$.pipe(
      retryWhen(errors =>
        errors.pipe(
          mergeMap(err => {
            if (err.status === 504 && maxRetries > 0) {
              return of(err)
                .pipe(delay(1000))
                .pipe(tap(() => maxRetries--));
            } else {
              return throwError(() => err);
            }
          })
        )
      )
    );
  };
