export interface FromToTuple {
  from: Date | number;
  to: Date | number;
}

export interface Overlap {
  error: FromToTuple;
  overlappingWith: FromToTuple;
}

/**
 * Compare two periods and return true if they overlap
 *
 * @param p1 an FromToTuple object
 * @param p2 an FromToTuple object
 * @returns true if the periods overlap
 */
export function overlappingPeriods(p1: FromToTuple, p2: FromToTuple): boolean {
  return p1.from < p2.to && p2.from < p1.to;
}

export function compareOverlappingPeriods(periods: FromToTuple[]): Overlap[] {
  return periods
    .sort((a, b) => (a.from > b.from ? 1 : -1))
    .reduce((acc, curr, i, arr) => {
      if (i === 0) return acc;
      if (overlappingPeriods(curr, arr[i - 1])) acc.push({ error: curr, overlappingWith: arr[i - 1] });
      else if (overlappingPeriods(arr[i - 1], curr)) acc.push({ error: curr, overlappingWith: arr[i - 1] });
      return acc;
    }, [] as Overlap[]);
}

export function millisToDate(num?: number | Date): Date {
  if (num == null || num instanceof Date) return num as Date;
  return (num != null ? new Date(num) : num) as Date;
}

export function dateToMillis(date?: Date | number) {
  if (date == null || typeof date === 'number') return date as number;
  return (date instanceof Date ? date.getTime() : date) as number;
}

export function getUserLocaleDateFormat() {
  const userLocaleDate = new Date().toLocaleDateString();
  const knownFormatDate = new Date().toLocaleDateString('en-US');

  const dateDelimeter = ['/', '-', '.', ' '].find(delimeter => userLocaleDate.includes(delimeter))!;
  const userDateParts = userLocaleDate.split(dateDelimeter);
  const [knownMonth, knownDay, knownYear] = knownFormatDate.split('/');
  const dayIndex = userDateParts.findIndex(part => part === knownDay);
  const monthIndex = userDateParts.findIndex(part => part === knownMonth);
  const yearIndex = userDateParts.findIndex(part => part === knownYear);
  return [dayIndex, monthIndex, yearIndex].map(index => ['dd', 'MM', 'yyyy'][index]).join(dateDelimeter);
}
