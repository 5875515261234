import { objToString } from './stringUtils';

export function objClone(obj: any) {
  try {
    const clone = structuredClone(obj);
    return clone;
  } catch (ex) {
    return JSON.parse(objToString(obj));
  }
}
