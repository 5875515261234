import { objToString } from './stringUtils';
import { trimObject } from './trim';

/**
 * Utility function for reading out variables from environment.
 * This will fetch from window.__env first, and then try angular
 * environment last.
 *
 * @param str the variable to fetch
 * @deprecated use EnvService.getEnv instead.
 */
export function getEnv(str: string, defaultVal?: any) {
  // Read environment variables from browser window
  const browserWindow = window || ({} as Record<string, unknown>);
  const browserWindowEnv: Record<string, unknown> = (browserWindow as { [key: string]: any })['__env'] || {};

  return browserWindowEnv[str] != null ? browserWindowEnv[str] : defaultVal;
}

/**
 * Make sure environment is available in global scope
 * This is a workaround to make sure current app's env is available inside
 * logic-shared as well
 *
 * @param env the environment object
 */
export function assignEnv(env: Record<string, unknown>) {
  const obj = filterEmpty(JSON.parse(objToString(env)));
  // Read environment variables from browser window
  const browserWindow = (window || {}) as { [key: string]: any };
  const browserWindowEnv: Record<string, unknown> = filterEmpty(
    (browserWindow as { [key: string]: any })['__env'] || {}
  );

  browserWindow.__env = Object.assign(obj, browserWindowEnv);
}

function filterEmpty(temp: { [key: string]: any }) {
  return trimObject(temp, (key, value) =>
    typeof value === 'string' && ['undefined', 'null', ''].includes(value) ? undefined : value
  ) as { [key: string]: any };
}
