import { WritableSignal } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

export interface SideEffectOptions<T = unknown> {
  before?: () => void;
  after?: (response: T) => void;
  activityFlag?: WritableSignal<boolean>;
  activityDelay?: number;
  target: Observable<T>;
}

export function sideEffect<T>(opts: SideEffectOptions<T>) {
  let isDone = false;
  let timeout: any;
  return (source$?: Observable<unknown>): Observable<T> => {
    if (!source$) {
      source$ = timer(0);
    }
    if (opts.activityFlag && !opts.before) {
      opts.before = () => {
        if (opts.activityDelay) {
          timeout && clearTimeout(timeout);
          timeout = setTimeout(() => !isDone && opts.activityFlag!.set(true), opts.activityDelay);
          return;
        }
        opts.activityFlag!.set(true);
      };
    }
    if (opts.activityFlag && !opts.after) {
      opts.after = () => opts.activityFlag!.set(false);
    }
    return source$.pipe(
      tap(() => {
        isDone = false;
        opts.before && opts.before();
      }),
      switchMap(() => opts.target),
      tap((args: T) => {
        isDone = true;
        timeout && clearTimeout(timeout);
        opts.after && opts.after(args);
      }),
      catchError(err => {
        isDone = true;
        timeout && clearTimeout(timeout);
        opts.after && opts.after(err);
        return err;
      }),
    ) as Observable<T>;
  };
}
